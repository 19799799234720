import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: "/",
        name: 'AnemoiWalletWeb',
        component: () => import('@/view/AnemoiWalletWeb.vue')
    },
    {
        path: '/:address',
        name: 'AnemoiWalletWeb_Referrer',
        component: () => import('@/view/AnemoiWalletWeb.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router